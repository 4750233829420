import ErrorReporter from 'breif-error-reporter'
import Vue from 'vue'
import EventBus from 'vue-breif-event-bus'
import infiniteScroll from 'vue-infinite-scroll'
import App from './App'
import AppUtil from './utils/app'
import router from './router'
import Navigation from './utils/navigation/index'
import Native from './utils/native'
import Service from './service'
import ActionSheet from './components/ActionSheet'
import AlertDialog from './components/AlertDialog'
import Toast from './components/Toast'
import ChunkLoadError from './components/ChunkLoadError'
import axios from './utils/axios'
import Ajax from './utils/ajax'
import wx from 'wxjssdk-copy'
import SpaWxjssdkUtil from './utils/lib/spa-wxjssdk-util'
import Util from './utils/util'
import api from './utils/api'

window.wx = wx

Vue.config.devtools = process.env.VUE_APP_NAME === 'local'
Vue.config.productionTip = process.env.VUE_APP_NAME === 'local'

ErrorReporter.setConfig({
    vue: Vue,
    vueRouter: router,
    axios: axios,
    axiosIgnore: [
        /web-error-report/
    ],
    onResourceLoadError (event) {
        if (event.target && event.target instanceof HTMLScriptElement) {
            if (new RegExp(`${process.env.VUE_APP_ASSETS_DIR}/[\\d.]+/js`).test(event.target.src) && vueRoot) {
                vueRoot.chunkLoadErrorShow = true
            }
        }
    },
    onReport (message, reportType, extraData) {
        if (reportType === 'unhandledrejection' && message && message.indexOf('via a navigation guard') > -1) {
            return
        }
        let uid = ''
        if (appUtil) {
            if (appUtil.siteState) {
                uid = appUtil.siteState.uid
            }
        }

        const accessToken = localStorage.accessToken || ''
        const clientId = localStorage.client_id || ''

        const openid = localStorage.openid || ''
        const miniOpenid = sessionStorage.mini_openid || ''

        const systemInfo = this.getSystemInfo()
        const href = location.href
        const path = location.pathname
        const search = location.search ? location.search.substring(1) : ''
        const cookieClientId = this.getCookie('client_id')
        const cookieAccessToken = this.getCookie('accessToken')
        let cookie = []
        cookieClientId && cookie.push(cookieClientId)
        cookieAccessToken && cookie.push(cookieAccessToken)
        cookie = cookie.join('; ')

        const native = (appUtil && appUtil.isNative) || false

        const reportData = {
            ...systemInfo,
            uid,
            accessToken,
            clientId,
            openid,
            miniOpenid,
            href,
            path,
            search,
            cookie,
            native: native,
            message,
            reportType,
            extraData: JSON.stringify(extraData),
            prod: 'app'
        }

        const _ajax = (appUtil && appUtil.ajax) || Ajax

        return _ajax.post(Service.error_report, {
            data: JSON.stringify(reportData)
        })
    }
})

SpaWxjssdkUtil.defaults.debug = false
SpaWxjssdkUtil.defaults.jsApiList = [
    'checkJsApi',
    'openLocation',
    'onMenuShareTimeline',
    'onMenuShareAppMessage'
]
SpaWxjssdkUtil.defaults.request = function (url) {
    const _ajax = (appUtil && appUtil.ajax) || Ajax
    return _ajax.get(Service.weixin_jssdk_sign, {
        url: url
    }).then((res) => {
        if (res.code === 200) {
            return res.data
        }
    })
}
SpaWxjssdkUtil.defaults.onSignInvalid = function (error, info) {
    ErrorReporter.makeReport(error, 'invalid sign', info)
}

Vue.mixin({
    computed: {
        $reporter () {
            return ErrorReporter
        }
    },
    methods: {
        getDefShareData () {
            const image = require('./assets/img/logo_share.jpg')
            return {
                ...{
                    title: (process.env.VUE_APP_NAME !== 'online' ? '[测试环境]' : '') +
                        process.env.VUE_APP_DEFAULT_SHARE_TITLE,
                    desc: process.env.VUE_APP_DEFAULT_SHARE_DESC,
                    link: process.env.VUE_APP_DEFAULT_SHARE_LINK,
                    imgUrl: image
                }
            }
        }
    }
})

if (location.href.indexOf('vconsole') > -1) {
    ErrorReporter.enableVConsole(false)
}

Vue.use(EventBus)
Vue.use(Navigation, { router })
Vue.use(infiniteScroll)

const appUtil = new AppUtil('app', Vue)

window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload()
    }
}, false)

console.log('enter app')

// eslint-disable-next-line no-unused-vars
function bindEventBusToNative (sdk) {
    const pageId = Util.randomString('xxxxxyyyyy')
    const webDispatchApi = 'webEventBusDispatch'
    const nativeDispatchApi = 'nativeEventBusDispatch'

    // 只有app版本支持以上两个api的时候，才能开放以下的功能
    const eventBus = Vue.prototype.$eventBus.core
    const trigger = eventBus.trigger.bind(eventBus)
    const webDispatch = sdk.registerApi(webDispatchApi).bind(sdk)

    sdk.register(nativeDispatchApi, (message, responseCallback) => {
        const event = sdk.toJson(message)
        // 如果消息是从自己所在的webview转发出来的，则下面的trigger不会处理
        if (event.pageId !== pageId) {
            trigger(event.type, ...(sdk.toJson(event.data)))
        }
        responseCallback(`${nativeDispatchApi}:ok`)
    })

    eventBus.trigger = (event, ...data) => {
        // 让本webview内的eventBus保持正常的使用模式
        trigger(event, ...data)

        // 借助app作为跳板，将本webview内的消息派发到其它webview
        webDispatch({
            type: event,
            pageId: pageId,
            data: JSON.stringify(data)
        }, {})
    }
}

let vueRoot = null
Native.ready().then((isNative) => {
    console.log('ready to inject native', isNative)
    appUtil.injectNative(isNative ? Native : null)
    if (isNative) {
        // bindEventBusToNative(Native)
    }

    window.clickInnerLink = function (a) {
        let href = ''
        if (a.dataset.nativeHref && isNative) {
            location.href = a.dataset.nativeHref
            href = a.dataset.nativeHref
        } else if (a.dataset.h5Href) {
            router.push({ path: a.dataset.h5Href })
            href = a.dataset.h5Href
        }

        if (/(series\/detail|series\/video|video\/detail|)/.test(href)) {
            appUtil.ajax.post(Service.page_view_stats, {
                page: 'topic_video_click'
            })
        }
    }

    if (location.search !== '') {
        const query = {}
        for (const [, value] of location.search.split('?')[1]
            .split('&').entries()) {
            const parts = value.split('=')
            query[parts[0]] = parts[1]
        }

        if ((appUtil.isMini || process.env.VUE_APP_NAME !== 'online') && query.mini_openid) {
            sessionStorage.setItem('mini_openid', query.mini_openid)
            api.setClientId(query.mini_openid)
        }

        if (query.app_auth) {
            sessionStorage.setItem('app_auth', query.app_auth)
        }
        console.log('app params', query)
    }

    appUtil.initState().then(() => {
        appUtil.injectVue(router)
        Vue.app = Vue.prototype.$app = appUtil

        console.log('app init')

        if (appUtil.siteState.uid === 5277 && process.env.VUE_APP_NAME !== 'local') {
            ErrorReporter.enableVConsole(false)
        }

        vueRoot = new Vue({
            el: '#app',
            router,
            components: { App, AlertDialog, Toast, ChunkLoadError, ActionSheet },
            data: {
                alertVisible: false,
                alert: {},
                toast: {
                    type: Object,
                    default: null
                },
                chunkLoadErrorShow: false,
                actionsShown: false,
                actionItems: []
            },
            render (h) {
                return h('div', {
                        attrs: {
                            id: 'app'
                        }
                    },
                    [
                        h('app'),
                        h('toast', {
                            props: {
                                'new-item': this.toast
                            }
                        }),
                        h('alert-dialog', {
                            props: this.alert,
                            model: {
                                value: this.alertVisible,
                                callback: $v => {
                                    this.alertVisible = $v
                                }
                            }
                        }),
                        h('chunk-load-error', {
                            props: {
                                show: this.chunkLoadErrorShow
                            },
                            on: {
                                model: value => {
                                    this.chunkLoadErrorShow = value
                                }
                            }
                        }),
                        h('action-sheet', {
                            props: {
                                items: this.actionItems
                            },
                            model: {
                                value: this.actionsShown,
                                callback: $v => {
                                    this.actionsShown = $v
                                }
                            },
                            on: {
                                select: this.actionSelected
                            }
                        })
                    ])
            },
            methods: {
                showAlert (options = {}) {
                    this.alertVisible = true
                    this.alert = options
                },
                showToast ({
                               text, duration, width, onDismiss
                           } = {}) {
                    this.toast = {
                        text, duration, width, onDismiss
                    }
                },
                showActions (actions) {
                    this.actionItems = actions
                    this.actionsShown = true
                },
                actionSelected (item) {
                    if (item.callback) {
                        item.callback()
                    }
                }
            }
        })
    })
})
